<template>
  <div id="sign-in">
    <div id="sign-in-logo" class="mb-80">
      <el-row>
        <el-col>
          <img src="img/logo.png" alt="logo" />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <span id="logo-sub">MODERATION</span>
        </el-col>
      </el-row>
    </div>

    <div id="sign-in-button">
      <el-button class="btn-sign-in small-caps" round @click="onSignInClicked" :loading="loading">
        <i class="fa fa-google"></i>
        SIGN IN WITH AUTH0
      </el-button>
    </div>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import { Auth0Lock } from 'auth0-lock'

const lock = new Auth0Lock(process.env.VUE_APP_AUTH0_CLIENT_ID, process.env.VUE_APP_AUTH0_DOMAIN, {
    auth: {
      responseType: 'token id_token',
      params: { scope: 'openid email profile' },
      redirect: false,
      allowedConnections: ['google-oauth2', 'facebook', 'twitter']
    }
  })

export default {
  name: 'SignIn',

  data () {
    return {
      loading: false
    }
  },
  computed: mapGetters(['isAuthenticated']),

  async mounted () {
    if (this.isAuthenticated) {
      this.$router.replace('/experiences')
      return
    }

    if (this.$route.query.jwt && this.$route.query.jwt.length > 0) {
      this.$store.commit('setToken', this.$route.query.jwt)
      this.$store.commit('setHandle', this.$route.query.handle)
      this.$store.commit('setModeratorId', this.$route.query.userId)
      this.$store.commit('setFirebaseToken', this.$route.query.firebaseToken)
      this.$store.commit('setUserProfilePicture', this.$route.query.picture)

      await this.$firebase.auth().signInWithCustomToken(this.$route.query.firebaseToken)
    }
  },

  methods: {
    async onSignInClicked () {
      this.loading = true
      const ky = this.$ky
      

      try {
        lock.show({
      allowedConnections: ['google-oauth2']
    })
      lock.on('authenticated', function (authResult) {
        lock.hide()
  lock.getUserInfo(authResult.accessToken, async function (error, profileResult) {
    if (error) {
      // Handle error
    }
    const { data: { redirectUrl }} = await ky.post(`${process.env.VUE_APP_API_URL}/auth0?access_token=${authResult.accessToken}`, {
        json: profileResult
    })

    window.location.href = redirectUrl
    window.location.reload()
  });
})
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colours.scss';

#sign-in {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

#sign-in-logo {
  text-align: center;
}

img {
  width: 150px;
}

#logo-sub {
  letter-spacing: 4px;
}

.btn-sign-in {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-width: 2px;
  border-color: $twitter-blue-logo;
}
</style>
